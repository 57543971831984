import Container from "react-bootstrap/Container";
import Header from "../components/header";

export default function Imprint() {
	return (
		<div>
			<Header />
			<Container>
				<h3>Impressum</h3>
				<h5>Angaben gemäß § 5 Telemediengesetz</h5>
				<p>
					Felix Oppermann <br />
					Oppermann Software Development
					<br />
					Hirschberger Straße 27
					<br />
					31135 Hildesheim
				</p>
				<p>USt-IdNr.: DE363693794</p>
				<h5>Kontakt</h5>
				<p>
					E-Mail: management@emergency-hamburg.com
					<br />
					Supportanfragen können an der oben genannten E-Mail-Adresse leider nicht bearbeitet
					werden. Bitte nutze dafür support@emergency-hamburg.com.
				</p>
				<h5>Gewerbeanmeldung</h5>
				<p>
					Die Gewerbeanmeldung nach § 14 GewO wurde am 23.05.2023 durch die Stadt Hildesheim
					erteilt.
				</p>
				<h5>Verbraucherstreitbeilegung / Universalschlichtungsstelle</h5>
				<p>
					Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
					Verbraucherschlichtungsstelle teilzunehmen.
				</p>
			</Container>
		</div>
	);
}
